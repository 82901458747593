import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Route, Switch, useLocation, useParams } from "wouter";
import { NestedCompany, NestedCurrency, NestedQuota } from "@revelate/types";
import type { KeyedMutator } from "swr";
import { GoalsTable } from "./goals-table";
import { getQuotaLabel } from "@/lib/app";
import { GoalsForm } from "./goals-form";
import { Plus } from "lucide-react";

const GoalsFormPage = ({
  quota,
  currencies,
  mutate,
}: {
  quota: NestedQuota;
  currencies?: NestedCurrency[];
  mutate: KeyedMutator<NestedQuota | null>;
}) => {
  const { goalId } = useParams();
  return (
    <div className="space-y-6">
      <div className="flex">
        <div>
          <h3 className="text-lg font-medium">
            {goalId ? "Edit sales goal for" : "Add new sales goal for"}{" "}
            {getQuotaLabel(quota)}
          </h3>
          <p className="text-sm text-muted-foreground">
            Set a monthly target and assign employees.
          </p>
        </div>
      </div>
      <GoalsForm quota={quota} currencies={currencies} mutate={mutate} />
    </div>
  );
};

export default function GoalsPage({
  quota,
  currencies,
  mutate,
}: {
  quota: NestedQuota;
  currencies?: NestedCurrency[];
  mutate: KeyedMutator<NestedQuota | null>;
}) {
  const [, setLocation] = useLocation();

  return (
    <Switch>
      <Route path="/">
        <div className="space-y-6">
          <div className="flex">
            <div>
              <h3 className="text-lg font-medium">
                Sales Goals for {getQuotaLabel(quota)}
              </h3>
              <p className="text-sm text-muted-foreground">
                Define sales goals and assign them to employees.
              </p>
            </div>
            <div className="ml-auto flex items-center gap-2">
              <Button
                onClick={() => setLocation("/edit")}
                className="bg-[#6B6397]"
              >
                <Plus className="mr-2 h-4 w-4" />
                Add new goal
              </Button>
            </div>
          </div>
          <Separator />
          <GoalsTable quota={quota} mutate={mutate} />
        </div>
      </Route>
      <Route path="/edit/:goalId?">
        <GoalsFormPage quota={quota} currencies={currencies} mutate={mutate} />
      </Route>
    </Switch>
  );
}
