import { supabaseClient } from "./client";
import { Database, DealRole, NestedUser } from "@revelate/types";

export const transferDeals = async (
  fromUser: NestedUser,
  toUser: NestedUser
) => {
  const { id: fromUserId } = fromUser;
  const { id: toUserId, provider_id } = toUser;

  const query = supabaseClient
    .from("deals")
    .update({ owner_id: toUserId, provider_owner_id: provider_id })
    .eq("owner_id", fromUserId);
  const { error } = await query;
  if (error) {
    console.error("Error transferring deals", error);
    throw error;
  }
};

export const updateDeal = async (
  dealId: number,
  data: Partial<Database["public"]["Tables"]["deals"]["Row"]>
) => {
  const { error } = await supabaseClient
    .from("deals")
    .update({
      ...data,
      updated_at: new Date().toISOString(),
    })
    .eq("id", dealId);
  if (error) {
    console.error("Error updating deal", error);
    throw error;
  }
};

export const upsertDealUser = async (
  deal_id: number,
  user_id: string,
  role: DealRole
) => {
  const { data: existingDealUser, error: existingDealUserError } =
    await supabaseClient
      .from("user_deals")
      .select()
      .eq("deal_id", deal_id)
      .eq("user_id", user_id)
      .eq("role", role)
      .returns<Database["public"]["Tables"]["user_deals"]["Row"][]>()
      .maybeSingle();
  if (existingDealUserError) {
    console.error("Supabase addDealCollaborator Error:", existingDealUserError);
    return null;
  }
  if (existingDealUser) {
    return existingDealUser;
  }
  const dealUser: Database["public"]["Tables"]["user_deals"]["Insert"] = {
    deal_id,
    user_id,
    role,
  };
  const { data, error } = await supabaseClient
    .from("user_deals")
    .upsert(dealUser, {
      // onConflict: "id",
      ignoreDuplicates: true,
    })
    .select()
    .returns<Database["public"]["Tables"]["user_deals"]["Row"][]>()
    .maybeSingle();
  if (error) {
    console.error("Supabase addDealCollaborator Error:", error);
    return null;
  }
  return data;
};
