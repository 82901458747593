import { NestedToken, TokenProvider } from "@revelate/types";

export const getToken = (tokens: NestedToken[], provider: TokenProvider) => {
  return tokens.find((token) => token.provider === provider);
};

export const getProviderName = (errorText: string): string | null => {
  if (errorText.toLowerCase().includes("fortnox")) return "fortnox";
  if (errorText.toLowerCase().includes("hubspot")) return "hubspot";
  return null;
};

export const getProviderAuthUrl = (
  company_id: number,
  providerName: string
): string => {
  return (
    import.meta.env.VITE_API_URL +
    `/oauth/${providerName}/authenticate/${company_id}`
  );
};
