import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { patchAPI } from "@/lib/api";
import { getUser, updateDeal, upsertDealUser } from "@/lib/supabase";
import { getDealOwner } from "@revelate/calc";
import { NestedDeal, NestedToken, NestedUser } from "@revelate/types";

import { getFullName } from "@revelate/utils";
import { Row } from "@tanstack/react-table";
import { useState } from "react";
import { KeyedMutator } from "swr";

export const OwnerSelect = ({
  row,
  users,
  mutate,
  tokens,
}: {
  row: Row<NestedDeal>;
  users: NestedUser[];
  mutate: KeyedMutator<NestedDeal[]>;
  tokens?: NestedToken[];
}) => {
  const [selectedOwner, setSelectedOwner] = useState<string | undefined>(
    getDealOwner(row.original)?.id
  );

  return (
    <Select
      // disabled={row.original.is_read_only}
      value={selectedOwner}
      onValueChange={async (value: string) => {
        const token = tokens?.find((t) => t.provider === row.original.provider);
        const { allow_update } = token || {};
        const user = await getUser(value);
        if (allow_update && user && user.provider_id) {
          // TODO: Create a unified update format for all providers
          await patchAPI(
            `/providers/${row.original.provider}/deals/${row.original.company_id}/${row.original.provider_id}`,
            JSON.stringify({
              properties: {
                hubspot_owner_id: user.provider_id,
              },
            })
          );
        }

        await upsertDealUser(row.original.id, value, "owner");
        await updateDeal(row.original.id, {
          // owner_id: value,
          is_read_only: allow_update ? false : true,
        });
        setSelectedOwner(value);
        mutate();
      }}
    >
      <SelectTrigger className="min-w-[150px]">
        <SelectValue placeholder="Select owner" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {users?.map((user) => (
            <SelectItem
              disabled={!user.is_active}
              key={user.id}
              value={user.id}
            >
              {getFullName(user)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
