import {
  CalculationPeriod,
  NestedQuota,
  TargetMetricType,
} from "@revelate/types";

type QuotaItem = {
  label: string;
  value: TargetMetricType;
  unit: string;
};

export const TARGET_METRICS: QuotaItem[] = [
  {
    label: "Closed Deal Value",
    unit: "SEK",
    value: "target_deal_value",
  },
  {
    label: "Closed Onboarding Fees",
    unit: "SEK",
    value: "target_onboarding_value",
  },
  {
    label: "Number of Deals",
    unit: "deals",
    value: "target_number_of_deals",
  },
];

type CalculationPeriodItem = {
  label: string;
  value: CalculationPeriod;
};

export const CALCULATION_PERIODS: CalculationPeriodItem[] = [
  {
    label: "Current Period",
    value: "current_period",
  },
  {
    label: "Year To Date",
    value: "year_to_date",
  },
];

export const getCalculationPeriodLabelFromValue = (
  value?: string
): string | null => {
  const item = CALCULATION_PERIODS.find((item) => item.value === value);
  return item?.label ?? null;
};

export const getCalculationPeriodValues = () => {
  return CALCULATION_PERIODS.map((item) => item.value);
};

export const getQuotaLabel = (quota: NestedQuota): string | null => {
  const { name, target_metric } = quota;
  const item = TARGET_METRICS.find((item) => item.value === target_metric);
  return item?.label ? `${name} (${item.label})` : null;
};

export const getQuotaValues = () => {
  return TARGET_METRICS.map((item) => item.value);
};

export const getQuotaUnitFromValue = (
  value?: TargetMetricType,
  currency?: string
): string | null => {
  if (value === "target_number_of_deals") return "deals";
  return currency || null;
};

export const getGoalUsersCountFromQuota = (quota: NestedQuota) => {
  let count = 0;
  quota.goals.forEach((g) => {
    count += g.users.length;
  });
  return count;
};
