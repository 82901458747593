import ExcelJS from "exceljs";

import { NestedUser, TODO } from "@revelate/types";
import {
  capitalizeFirstLetter,
  getFullName,
  sortByStringKey,
} from "@revelate/utils";

type UserWithCommissions = NestedUser & {
  commissions: TODO[];
};

export const exportToExcel = async ({
  users,
}: {
  users: UserWithCommissions[];
}) => {
  const wb = new ExcelJS.Workbook();
  wb.creator = "Revelate Technologies AB";
  wb.created = new Date();
  wb.modified = new Date();
  wb.lastModifiedBy = "Revelate Technologies AB";

  for await (const user of users) {
    const { commissions } = user;
    const cleanedCommissions = commissions
      .map((commission) => {
        const {
          year,
          month,
          accelerator,
          accelerator_id,
          deal,
          deal_id,
          rate,
          commission_basis,
          amount,
          status,
          created_at,
          updated_at,
        } = commission;
        return {
          year,
          month,
          deal: deal ? deal.name : deal_id ? deal_id : "Additional Commissions",
          closed_at:
            deal && deal.closed_at
              ? new Date(deal.closed_at)
              : "Based on all deals for the period",
          accelerator: accelerator.name || accelerator_id,
          commission_basis,
          rate,
          amount,
          status: status ? capitalizeFirstLetter(status) : "",
          provider:
            deal && deal.provider ? capitalizeFirstLetter(deal.provider) : "",
          provider_id: deal && deal.provider_id ? deal.provider_id : null,
          created_at: created_at ? new Date(created_at) : null,
          updated_at: updated_at ? new Date(updated_at) : null,
        };
      })
      .sort(sortByStringKey("deal"))
      .sort(sortByStringKey("month"))
      .sort(sortByStringKey("year"));

    const sheet = wb.addWorksheet(getFullName(user), {
      views: [{ state: "frozen", xSplit: 2 }],

      // headerFooter: {
      //   firstHeader: "Hello Exceljs",
      //   firstFooter: "Hello World",
      // },
    });
    // workbook.eachSheet(function(worksheet, sheetId) {
    //   // ...
    // });
    sheet.columns = [
      { header: "Year", key: "year", width: 6 },
      { header: "Month", key: "month", width: 6 },
      { header: "Deal", key: "deal", width: 30 },
      {
        header: "Closed At",
        key: "closed_at",
        width: 10,
        style: { numFmt: "yyyy-mm-dd" },
      },
      {
        header: "Accelerator",
        key: "accelerator",
        width: 15,
      },
      { header: "Rate", key: "rate", width: 8, style: { numFmt: "0.00%" } },
      {
        header: "Commission Basis",
        key: "commission_basis",
        width: 15,
        style: { numFmt: '#,##0 "kr"_);\\(#,##0 "kr"\\)' },
      },
      {
        header: "Amount",
        key: "amount",
        width: 12,
        style: { numFmt: '#,##0 "kr"_);\\(#,##0 "kr"\\)' }, // '"kr"#,##0.00;[Red]-"kr"#,##0.00'
      },
      // { header: 'Currency', key: 'Currency', width: 10 },
      { header: "Status", key: "status", width: 10 },
      {
        header: "Provider",
        key: "provider",
        width: 10,
      },
      {
        header: "Provider ID",
        key: "provider_id",
        width: 10,
      },
      {
        header: "Created At",
        key: "created_at",
        width: 10,
        style: { numFmt: "yyyy-mm-dd" },
      },
      {
        header: "Updated At",
        key: "updated_at",
        width: 10,
        style: { numFmt: "yyyy-mm-dd" },
      },
    ];

    sheet.autoFilter = {
      from: "A1",
      to: "I1",
    };

    const amountColumn = sheet.getColumn("amount");
    amountColumn.font = { bold: true };
    // amountColumn.fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "CDDCAC" },
    //   // bgColor: { argb: "CDDCAC" },
    // };

    const firstRow = sheet.getRow(1);
    firstRow.font = { bold: true, size: 11, color: { argb: "ffffff" } };
    firstRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "6b6397" },
      bgColor: { argb: "6b6397" },
    };
    firstRow.alignment = { vertical: "middle", horizontal: "left" };
    firstRow.height = 15;

    for await (const commission of cleanedCommissions) {
      const row = sheet.addRow(commission);
      row.alignment = { horizontal: "left" };
    }
  }

  const buffer = await wb.xlsx.writeBuffer();
  return buffer;
};
