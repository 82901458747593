import { useLocation, useParams } from "wouter";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
// import { toast } from "@/components/ui/use-toast";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { CommissionSelector } from "./components/commission-selector";
import { Badge } from "@/components/ui/badge";
import { CriteriaCombobox } from "./components/criteria-combobox";
import { ConditionCombobox } from "./components/condition-combobox";
import {
  criteriaRadioButtons,
  getCommissionBasisLabel,
  getCommissionBasisRadioButtons,
} from "@/lib/app";
import { CommissionBasedOn, Database, NestedPlan, TODO } from "@revelate/types";
import { upsertAccelerator } from "@/lib/supabase/accelerators";
import type { KeyedMutator } from "swr";
import { Minus, Plus } from "lucide-react";
import { replaceConditionsForAccelerator } from "@/lib/supabase/conditions";
import { zodEnum } from "@/lib/utils";
import { getCommissionBasisValues } from "@/lib/app";

const profileFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  rate: z
    .string()
    .default("15")
    .transform((val, ctx) => {
      const parsed = Number.parseFloat(val);
      const regexpMoreThan2Decimals = /^\d+\.\d{3,}$/;

      if (val.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a commission rate",
        });
        return z.NEVER;
      } else if (isNaN(parsed)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Rate must be a number between 0 and 500",
        });
        return z.NEVER;
      } else if (parsed < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Rate must be at least 0%.",
        });
        return z.NEVER;
      } else if (parsed > 500) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Rate must be at most 500%.",
        });
        return z.NEVER;
      } else if (regexpMoreThan2Decimals.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Cannot set more than 2 decimals",
        });
        return z.NEVER;
      }
      return parsed;
    }),
  commission_based_on: z.enum(zodEnum(getCommissionBasisValues() as string[]), {
    required_error: "Please select the basis for commission payments.",
  }),
  fixed_value_amount: z
    .string({
      required_error: "Please enter a a fixed value for compensation.",
    })
    .optional(),
  // .min(0, {
  //   message: "Amount must be at least 0.",
  // })
  criteria: z.string({
    required_error: "Please select the rule basis for commissions.",
  }),
  conditions: z
    .array(
      z
        .object({
          id: z.number().optional(),
          key: z.object({
            key_name: z.string({
              required_error: "Please select a key.",
            }),
            quota_id: z.number().optional(),
          }),
          operator: z.string({
            required_error: "Please select an operator.",
          }),
          value: z.string({
            required_error: "Please set a value.",
          }),
          value_from: z.string({
            required_error: "Please set a value.",
          }),
          value_to: z.string({
            required_error: "Please set a value.",
          }),
          value_list: z.string({
            required_error: "Please set a value.",
          }),
        })
        .optional()
    )
    .optional(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export type CustomFormItemProps = {
  value: TODO;
  onChange: (value: TODO) => void;
};

export function AcceleratorsForm({
  plan,
  mutate,
}: {
  plan: NestedPlan;
  mutate: KeyedMutator<NestedPlan | null>;
}) {
  const { accelerators } = plan || {};
  const { acceleratorId } = useParams();
  const currentAccelerator = accelerators?.find(
    (a) => a.id === Number(acceleratorId)
  );

  // This can come from your database or API.
  const condition = {
    key: {
      key_name: "",
      quota_id: undefined,
    },
    operator: "",
    value: "",
    value_from: "",
    value_to: "",
    value_list: "",
  };
  const defaultValues: Partial<ProfileFormValues> = {
    name: currentAccelerator?.name || "",
    rate: currentAccelerator ? currentAccelerator.rate * 100 : 15,
    fixed_value_amount: currentAccelerator
      ? currentAccelerator?.fixed_value_amount?.toString()
      : undefined,
    conditions: currentAccelerator?.conditions
      ? currentAccelerator.conditions.map((c) => {
          return {
            key: {
              key_name: c.key || "",
              quota_id: c.quota_id || undefined,
            },
            operator: c.operator || "",
            value: c.value ? c.value.toString() : "",
            value_from: c.value_from ? c.value_from.toString() : "",
            value_to: c.value_to ? c.value_to.toString() : "",
            value_list: c.value_list ? c.value_list.join(",") : "",
          };
        })
      : [condition],
    commission_based_on:
      currentAccelerator?.commission_based_on || "deal_value",
    criteria: currentAccelerator?.criteria || "always",
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });
  const [, setLocation] = useLocation();

  const { fields, append, remove } = useFieldArray({
    name: "conditions",
    control: form.control,
  });

  async function onSubmit(data: ProfileFormValues) {
    // toast({
    //   title: "You submitted the following values:",
    //   description: (
    //     <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
    //       <code className="text-white">{JSON.stringify(data, null, 2)}</code>
    //     </pre>
    //   ),
    // });
    const acceleratorData: Database["public"]["Tables"]["accelerators"]["Insert"] =
      {
        id: currentAccelerator?.id,
        plan_id: plan?.id,
        name: data.name,
        rate: data.rate / 100,
        commission_based_on: data.commission_based_on,
        fixed_value_amount:
          data.fixed_value_amount && data.fixed_value_amount.length > 0
            ? Number(data.fixed_value_amount)
            : null,
        criteria: data.criteria,
      };
    const accelerator = await upsertAccelerator(acceleratorData);
    if (!accelerator) {
      return;
    }
    const conditionsData: Database["public"]["Tables"]["conditions"]["Insert"][] =
      data?.conditions?.map((c) => ({
        ...c,
        key: c?.key?.key_name,
        quota_id: c?.key?.quota_id,
        value: c?.value !== "" ? Number(c?.value) : null,
        value_from: c?.value_from !== "" ? Number(c?.value_from) : null,
        value_to: c?.value_to !== "" ? Number(c?.value_to) : null,
        value_list: c?.value_list !== "" ? c?.value_list.split(",") : null,
        accelerator_id: accelerator.id,
      })) || [];
    const conditions =
      conditionsData && conditionsData.length > 0
        ? await replaceConditionsForAccelerator(accelerator.id, conditionsData)
        : [];
    mutate({
      ...plan,
      accelerators: plan?.accelerators.map((a) =>
        accelerator && a.id === accelerator.id
          ? {
              ...accelerator,
              conditions: conditions || [],
            }
          : a
      ),
    });
    setLocation(`/`);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input
                  placeholder={`${form.watch(
                    "rate"
                  )}% commission on ${getCommissionBasisLabel(
                    plan,
                    form.watch("commission_based_on") as CommissionBasedOn
                  )?.toLowerCase()}`}
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Give the accelerator a name visible for your employees.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="rate"
          render={({ field: { value, onChange } }) => (
            <CommissionSelector value={value} onChange={onChange} />
          )}
        />
        <div>
          <Badge className="text-sm" variant="secondary">
            based on
          </Badge>
        </div>
        <FormField
          control={form.control}
          name="commission_based_on"
          render={({ field }) => (
            <FormItem className="space-y-3">
              {/* <FormLabel>based on</FormLabel> */}
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  {getCommissionBasisRadioButtons(plan)?.map((c, index) => (
                    <FormItem
                      key={index}
                      className="flex items-center space-x-3 space-y-0"
                    >
                      <FormControl>
                        <RadioGroupItem value={c.value} />
                      </FormControl>
                      <FormLabel className="font-normal">{c.label}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {form.watch("commission_based_on") === "fixed_value" && (
          <FormField
            control={form.control}
            name="fixed_value_amount"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Enter amount" {...field} />
                </FormControl>
                <FormDescription>
                  Set a fixed amount in SEK to calculate the commission based
                  on.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <div>
          <Badge className="text-sm" variant="secondary">
            payment frequency
          </Badge>
        </div>
        <FormField
          control={form.control}
          name="criteria"
          render={({ field }) => (
            <FormItem className="space-y-3">
              {/* <FormLabel>based on</FormLabel> */}
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  {criteriaRadioButtons.map((c, index) => (
                    <FormItem
                      key={index}
                      className="flex items-center space-x-3 space-y-0"
                    >
                      <FormControl>
                        <RadioGroupItem value={c.value} />
                      </FormControl>
                      <FormLabel className="font-normal">{c.label}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <>
          {(form.watch("criteria") === "all_criteria_met" ||
            form.watch("criteria") === "any_criteria_met") && (
            <div className="space-y-2">
              {fields.map((field, index) => (
                <div key={index} className="flex flex-row gap-2">
                  <FormField
                    control={form.control}
                    key={`${field.id}.conditions.${index}.key`}
                    name={`conditions.${index}.key`}
                    render={({ field: { value, onChange } }) => (
                      <CriteriaCombobox value={value} onChange={onChange} />
                    )}
                  />
                  <FormField
                    control={form.control}
                    key={`${field.id}.conditions.${index}.operator`}
                    name={`conditions.${index}.operator`}
                    render={({ field: { value, onChange } }) => (
                      <ConditionCombobox
                        value={value}
                        onChange={onChange}
                        isQuota={false}
                        // TODO: check if the criteria for this specific row is a quota
                      />
                    )}
                  />
                  {["is", "gte", "lte", "lt", "gt"].includes(
                    form.watch(`conditions.${index}.operator`)
                  ) && (
                    <FormField
                      control={form.control}
                      key={`${field.id}.conditions.${index}.value`}
                      name={`conditions.${index}.value`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              className="h-9"
                              placeholder="Value"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  {form.watch(`conditions.${index}.operator`) === "range" && (
                    <FormField
                      control={form.control}
                      key={`${field.id}.conditions.${index}.value_from`}
                      name={`conditions.${index}.value_from`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              className="h-9"
                              placeholder="From value"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  {form.watch(`conditions.${index}.operator`) === "range" && (
                    <FormField
                      control={form.control}
                      key={`${field.id}.conditions.${index}.value_to`}
                      name={`conditions.${index}.value_to`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              className="h-9"
                              placeholder="To value"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  {form.watch(`conditions.${index}.operator`) === "list" && (
                    <FormField
                      control={form.control}
                      key={`${field.id}.conditions.${index}.value_list`}
                      name={`conditions.${index}.value_list`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              className="h-9"
                              placeholder="one,two,three"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={() => remove(index)}
                  >
                    <Minus className="mr-1 h-4 w-4" /> Remove
                  </Button>
                </div>
              ))}
              <Button
                type="button"
                variant="outline"
                size="sm"
                className="mt-2"
                onClick={() => append(condition)}
              >
                <Plus className="mr-1 h-4 w-4" /> Add criteria
              </Button>
            </div>
          )}
          <Button type="submit" className="bg-[#6B6397]">
            Continue
          </Button>
        </>
      </form>
    </Form>
  );
}
