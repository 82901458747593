import { Database } from "@revelate/types";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import {
  capitalizeFirstLetter,
  getFullName,
  getUserInitials,
} from "@revelate/utils";

export const UserAvatarList = ({
  users,
  customDescription,
}: {
  users: Database["public"]["Tables"]["users"]["Row"][];
  customDescription?: string;
}) => {
  return (
    <div className="flex items-center gap-1">
      {users?.map((u, index) => (
        <Tooltip key={index}>
          <TooltipTrigger>
            <Avatar className="h-8 w-8">
              <AvatarImage src={u?.avatar_url || ""} />
              <AvatarFallback>{getUserInitials(u)}</AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent>
            <div className="text-md font-semibold">{getFullName(u)}</div>
            <p className="text-sm text-muted-foreground">
              {customDescription || capitalizeFirstLetter(u?.role)}{" "}
            </p>
          </TooltipContent>
        </Tooltip>
      ))}
    </div>
  );
};
