import { useLocation } from "wouter";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { Database, NestedTeam } from "@revelate/types";
import { upsertTeam } from "@/lib/supabase";
import type { KeyedMutator } from "swr";
import { useAppStore } from "@/stores";
import { getRoute } from "@/lib/routes";
import { capitalizeFirstLetter } from "@revelate/utils";
import { useJune } from "@/hooks/useJune";

const profileFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const BasicsForm = ({
  team: editTeam,
  mutate,
}: {
  team?: NestedTeam;
  mutate: KeyedMutator<NestedTeam | null>;
}) => {
  const [, setLocation] = useLocation();
  const currentUser = useAppStore((state) => state.currentUser);
  const analytics = useJune();

  async function onSubmit(data: ProfileFormValues) {
    if (!currentUser || !currentUser.company_id) return;
    const teamData: Database["public"]["Tables"]["teams"]["Insert"] = {
      id: editTeam?.id,
      company_id: currentUser.company_id, // Required
      name: data.name,
    };
    const team = await upsertTeam(teamData);
    if (!team) return;
    // Track team changes
    analytics?.track(editTeam ? "Team edited" : "Team created", {
      id: team.id,
      name: team.name,
    });
    mutate({ ...team });
    setLocation(getRoute("teams", "team", team.id.toString(), "employees"));
  }

  // // This can come from your database or API.
  const defaultValues: Partial<ProfileFormValues> = {
    name: editTeam?.name || "",
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="My new team" {...field} />
              </FormControl>
              <FormDescription className="text-xs">
                Give a meaningful name to the team visible for your employees.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="bg-[#6B6397]">
          Continue
        </Button>
      </form>
    </Form>
  );
};
