import { Separator } from "@/components/ui/separator";
import { EmployeesTable } from "./employees-table";
import { NestedTeam } from "@revelate/types";
import type { KeyedMutator } from "swr";
import { EmployeeCombobox } from "./components/employee-combobox";

export default function EmployeesPage({
  team,
  mutate,
}: {
  team: NestedTeam;
  mutate: KeyedMutator<NestedTeam | null>;
}) {
  return (
    <div className="space-y-6">
      <div className="flex">
        <div>
          <h3 className="text-lg font-medium">Employees</h3>
          <p className="text-sm text-muted-foreground">
            Connect this team to employees to start calculating commissions.
          </p>
        </div>
        <div className="ml-auto flex items-center gap-2">
          <EmployeeCombobox team={team} mutate={mutate} />
        </div>
      </div>
      <Separator />
      <EmployeesTable team={team} mutate={mutate} />
    </div>
  );
}
