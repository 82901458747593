// import Image from "next/image";
// import Link from "next/link";

import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { NestedTeam } from "@revelate/types";
import { ConfirmDeletionDialog } from "../confirm-deletion";
import type { KeyedMutator } from "swr";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { deleteUserFromTeam } from "@/lib/supabase";
import { getFullName, getUserInitials, sortByStringKey } from "@revelate/utils";

export function EmployeesTable({
  team,
  mutate,
}: {
  team: NestedTeam;
  mutate: KeyedMutator<NestedTeam | null>;
}) {
  const { users } = team || {};

  return (
    <Card x-chunk="dashboard-05-chunk-3">
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>

              <TableHead>Name</TableHead>
              <TableHead className="hidden sm:table-cell">Email</TableHead>

              {/* <TableHead className="text-right"></TableHead> */}
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users?.sort(sortByStringKey("first_name")).map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar className="h-9 w-9">
                    <AvatarImage
                      src={user?.avatar_url || ""}
                      alt={getFullName(user)}
                    />
                    <AvatarFallback>{getUserInitials(user)}</AvatarFallback>
                  </Avatar>
                </TableCell>
                <TableCell>
                  <div className="font-medium">{getFullName(user)}</div>
                </TableCell>

                <TableCell className="hidden sm:table-cell">
                  {user?.email}
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-3">
                    <ConfirmDeletionDialog
                      key={index}
                      deleteId={user.id.toString()}
                      deleteOperation={async (id) => {
                        await deleteUserFromTeam(team.id, id);
                        const updatedUsers = users.filter(
                          (user) => user.id !== id
                        );
                        mutate({
                          ...team,
                          users: updatedUsers,
                        });
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
