import { useEffect, useState } from "react";
import { SignedInUser, TODO } from "@revelate/types";
import { useAppStore } from "@/stores";

export const useFeaturebase = (isDev: boolean) => {
  const currentUser = useAppStore((state) => state.currentUser);
  const [unviewedChangelogCount, setUnviewedChangelogCount] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getChangelogSettings = (user: SignedInUser) => {
    return {
      organization: "revelate", // Replace this with your featurebase organization name
      placement: "right", // Choose between right, left, top, bottom placement (Optional if fullscreenPopup is enabled)
      theme: "light", // Choose between dark or light theme
      fullscreenPopup: true, // Optional - Open a fullscreen announcement of the new feature to the user
      alwaysShow: true, // Optional - Always show the announcement to the user
      usersName: user?.first_name || "there", // Optional - Show the users name in the welcome message for the fullscreen popup
    };
  };

  const open = () => {
    setIsLoading(true);

    const win = window as TODO;
    if (typeof win.Featurebase === "function" && currentUser) {
      win.Featurebase(
        "initialize_changelog_widget",
        getChangelogSettings(currentUser)
      );

      // Manually wait for changelog to init
      window.setTimeout(() => {
        setIsLoading(false);
        win.Featurebase("manually_open_changelog_popup");
      }, 50);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!currentUser || !window || isDev) return;

    const script = document.createElement("script");
    script.src = "https://do.featurebase.app/js/sdk.js";
    script.id = "featurebase-sdk";
    document.head.appendChild(script);

    const win = window as TODO;

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }
    win.Featurebase(
      "initialize_changelog_widget",
      getChangelogSettings(currentUser)
    );

    win.Featurebase("initialize_feedback_widget", {
      organization: "revelate", // required
      theme: "light", // optional
      placement: "right", // optional - remove to hide the floating button
      email: currentUser.email, // optional
      // defaultBoard: "yourboardname", // optional - preselect a board
      metadata: {
        device_type: window.navigator.userAgent,
      }, // Attach session-specific metadata to feedback. Refer to the advanced section for the details: https://developers.featurebase.app/install/advanced#metadata
    });
    setUnviewedChangelogCount(win.Featurebase("unviewed_changelog_count"));

    return () => {
      document.head.removeChild(script);
    };
  }, [currentUser, isDev]);

  return { open, isLoading, unviewedChangelogCount };
};
