"use client";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Integration } from "@/constants/integrations";
import { updateTokenAllowUpdate } from "@/lib/supabase";
import { NestedToken } from "@revelate/types";
import { useState } from "react";
import { KeyedMutator } from "swr";
import { Link } from "wouter";

export function UpdateCheckbox({
  integration,
  token,
  mutate,
}: {
  integration: Integration;
  token: NestedToken;
  mutate: KeyedMutator<NestedToken[]>;
}) {
  const { allow_update } = token || {};
  const { updateSupported } = integration || {};

  const [checked, setChecked] = useState(allow_update || false);

  const handleCheckedChange = async (checked: boolean) => {
    if (token) {
      // Update token
      await updateTokenAllowUpdate(token.id, checked);
      setChecked(checked);
      mutate();
      // mutate((prev) => {
      //   return prev?.map((t) => {
      //     if (t.id === token.id) {
      //       return {
      //         ...t,
      //         allow_update: checked,
      //       };
      //     }
      //     return t;
      //   });
      // });
    }
  };

  return (
    <div className="items-top flex space-x-2 pt-4">
      <Checkbox
        disabled={!updateSupported}
        id="allow_update"
        checked={checked}
        onCheckedChange={handleCheckedChange}
      />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor="terms1"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Enable {integration.name} data update{" "}
          <Badge variant={updateSupported ? "default" : "outline"}>
            {updateSupported ? "Beta" : "Coming soon"}
          </Badge>
        </label>
        <p className="text-xs text-muted-foreground">
          Tick this box to allow update of {integration.name} source data
          <Button size={null} variant="link" className="pl-1 text-xs">
            <Link href="/deals">via the Deals page</Link>.
          </Button>
        </p>
      </div>
    </div>
  );
}
