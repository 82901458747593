import { Separator } from "@/components/ui/separator";
import { BasicsForm } from "./settings-form";
import { NestedTeam, NestedToken } from "@revelate/types";
import type { KeyedMutator } from "swr";

export const SettingsPage = ({
  team,
  mutate,
}: {
  team?: NestedTeam;
  mutate: KeyedMutator<NestedTeam | null>;
}) => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">General</h3>
        <p className="text-sm text-muted-foreground">
          Configure the basics of your team
        </p>
      </div>
      <Separator />
      <BasicsForm team={team} mutate={mutate} />
    </div>
  );
};
