import { cn } from "@/lib/utils";

export interface ISVGProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  parentClassName?: string;
  stroke?: string;
  height?: string;
}

export const Loader = ({
  size = 50,
  stroke = "#6B6397",
  className,
  parentClassName = "h-screen scale-300",
  ...props
}: ISVGProps) => {
  return (
    <div className={cn("flex items-center justify-center", parentClassName)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={cn("animate-spin", className)}
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </svg>
    </div>
  );
};
