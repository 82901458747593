// import Image from "next/image";
// import Link from "next/link";

import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { NestedPlan } from "@revelate/types";
import { ConfirmDeletionDialog } from "../confirm-deletion";
import type { KeyedMutator } from "swr";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { deleteTeamFromPlan } from "@/lib/supabase";
import { capitalizeFirstLetter, sortByStringKey } from "@revelate/utils";
import { getTeamInitials } from "@/lib/app";
import { Tooltip } from "recharts";
import { UserAvatarList } from "@/components/UserAvatarList";

export function TeamsTable({
  plan,
  mutate,
}: {
  plan: NestedPlan;
  mutate: KeyedMutator<NestedPlan | null>;
}) {
  const { teams } = plan || {};

  return (
    <Card x-chunk="dashboard-05-chunk-3">
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>

              <TableHead>Name</TableHead>
              <TableHead>Team Members</TableHead>

              <TableHead className="hidden sm:table-cell">Created by</TableHead>

              {/* <TableHead className="text-right"></TableHead> */}
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {teams?.sort(sortByStringKey("first_name")).map((team, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar className="h-9 w-9">
                    {/* <AvatarImage
                      src={team?.avatar_url || ""}
                      alt={team?.name}
                    /> */}
                    <AvatarFallback>{getTeamInitials(team)}</AvatarFallback>
                  </Avatar>
                </TableCell>
                <TableCell>
                  <div className="font-medium">{team?.name}</div>
                </TableCell>
                <TableCell>
                  <UserAvatarList users={team?.users} />
                </TableCell>

                <TableCell className="hidden sm:table-cell">
                  {team?.provider
                    ? capitalizeFirstLetter(team?.provider)
                    : "Manually created"}
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-3">
                    <ConfirmDeletionDialog
                      key={index}
                      deleteId={team.id.toString()}
                      deleteOperation={async (id) => {
                        await deleteTeamFromPlan(plan.id, id);
                        const updatedTeams = teams.filter(
                          (team) => team.id !== Number(id)
                        );
                        mutate({
                          ...plan,
                          teams: updatedTeams,
                        });
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
