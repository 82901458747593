import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@/components/ui/table";
import { NestedTeam, TODO } from "@revelate/types";

import {
  capitalizeFirstLetter,
  getFullName,
  getUserInitials,
} from "@revelate/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { ConfirmDeletionDialog } from "../edit/confirm-deletion";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { DataTableColumnHeader } from "@/deals/components/data-table-column-header";
import { UserAvatarList } from "@/components/UserAvatarList";
import { getTeamInitials } from "@/lib/app";

interface CreateTeamsTableColumnsProps {
  goToTeam: (teamId: string) => void;
  onDeleteTeam: (teamId: string) => Promise<void>;
}

export function createTeamsTableColumns({
  goToTeam,
  onDeleteTeam,
}: CreateTeamsTableColumnsProps): ColumnDef<NestedTeam, TODO>[] {
  return [
    {
      accessorKey: "avatar",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="" />
      ),
      cell: ({ row }) => {
        return (
          <Avatar>
            <AvatarFallback>{getTeamInitials(row?.original)}</AvatarFallback>
          </Avatar>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <>
            <div className="font-medium">{row?.original?.name}</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              {row?.original?.provider
                ? `Automatically created by ${capitalizeFirstLetter(row?.original?.provider)}`
                : "Manually created"}
            </div>
          </>
        );
      },
      enableSorting: true,
      sortingFn: (rowA, rowB) =>
        rowA?.original?.name?.localeCompare(rowB?.original?.name),
      enableHiding: false,
    },
    {
      id: "users",
      header: "Team Members",
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-1">
            <UserAvatarList users={row?.original?.users} />
          </div>
        );
      },
    },

    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-3">
            <Button
              onClick={() => goToTeam(row?.original?.id?.toString())}
              variant="default"
              size="icon"
              className="bg-[#6B6397]"
            >
              <Pencil className="h-4 w-4" />
            </Button>

            {!row?.original?.provider && (
              <ConfirmDeletionDialog
                key={row?.index}
                deleteId={row?.original?.id.toString()}
                deleteOperation={() =>
                  onDeleteTeam(row?.original?.id?.toString())
                }
              />
            )}
          </div>
        );
      },
    },
  ];
}

interface TeamsTableProps<TData, TValue> {
  teams: NestedTeam[];
  columns: ColumnDef<TData, TValue>[];
}

export function TeamsTable({ teams, columns }: TeamsTableProps<TODO, TODO>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: teams,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
