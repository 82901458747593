import { supabaseClient } from "./client";

export const deleteToken = async (jd: number): Promise<void> => {
  const { error } = await supabaseClient.from("tokens").delete().eq("id", jd);
  if (error) {
    throw error;
  }
};

export const updateTokenAllowUpdate = async (
  jd: number,
  allow_update: boolean
): Promise<void> => {
  const { error } = await supabaseClient
    .from("tokens")
    .update({
      allow_update,
    })
    .eq("id", jd);
  if (error) {
    throw error;
  }
};
