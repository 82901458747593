import { Link, useLocation } from "wouter";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useForm } from "react-hook-form";
import {
  Database,
  NestedCompany,
  NestedCurrency,
  NestedQuota,
  NestedToken,
  TokenProvider,
} from "@revelate/types";
import type { KeyedMutator } from "swr";
import { useAppStore } from "@/stores";
import { getRoute } from "@/lib/routes";
import { upsertQuota } from "@/lib/supabase/quotas";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  CALCULATION_PERIODS,
  TARGET_METRICS,
  getCalculationPeriodValues,
  getDealProvidersMultiSelectOptions,
  getQuotaValues,
} from "@/lib/app";
import { zodEnum } from "@/lib/utils";
import { dealTypesRadioButtons } from "@/lib/app";
import { useJune } from "@/hooks/useJune";
import { MultiSelect } from "@/components/ui/multi-select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

const profileFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  target_metric: z.enum(zodEnum(getQuotaValues() as string[]), {
    required_error: "Please pick a target metric.",
  }),
  currency_code: z.string(),
  calculation_period: z.enum(
    zodEnum(getCalculationPeriodValues() as string[]),
    {
      required_error: "Please pick a calculation period.",
    }
  ),
  providers: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
  status: z.enum(["draft", "published", "archived"]),
  deal_types: z
    .array(z.string())
    .refine((value) => value.some((item) => item), {
      message: "You have to select at least one item.",
    }),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const GeneralForm = ({
  quota: editQuota,
  tokens,
  company,
  currencies,
  mutate,
}: {
  quota?: NestedQuota;
  tokens?: NestedToken[];
  company?: NestedCompany;
  currencies?: NestedCurrency[];
  mutate: KeyedMutator<NestedQuota | null>;
}) => {
  const [, setLocation] = useLocation();
  const currentUser = useAppStore((state) => state.currentUser);
  const analytics = useJune();

  async function onSubmit(data: ProfileFormValues) {
    if (!currentUser || !currentUser.company_id) return;
    const quotaData: Database["public"]["Tables"]["quotas"]["Insert"] = {
      id: editQuota?.id,
      company_id: currentUser.company_id, // Required
      currency_id:
        currencies?.find((c) => c.code === data.currency_code)?.id ||
        company?.default_currency_id,
      name: data.name,
      deal_types: data.deal_types,
      target_metric: data.target_metric,
      calculation_period: data.calculation_period,
      providers: data.providers as TokenProvider[],
      status: data.status,
    };
    const quota = await upsertQuota(quotaData);
    if (!quota) return;
    // Track quota changes
    analytics?.track(editQuota ? "Quota edited" : "Quota created", {
      id: quota.id,
      name: quota.name,
      deal_types: quota.deal_types,
      target_metric: quota.target_metric,
    });
    mutate({ ...quota });
    setLocation(getRoute("quotas", "quota", quota.id.toString(), "goals"));
  }

  const defaultCurrency = currencies?.find(
    (c) => c.id === company?.default_currency_id
  );

  // // This can come from your database or API.
  const defaultValues: Partial<ProfileFormValues> = {
    name: editQuota?.name || "",
    target_metric: editQuota?.target_metric || "",
    currency_code: currencies?.find((c) => c.id === editQuota?.currency_id)
      ?.code,
    deal_types: editQuota?.deal_types || ["new_business", "existing_business"],
    calculation_period: editQuota?.calculation_period || "current_period",
    providers: editQuota?.providers || [],
    status: editQuota?.status || "draft",
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Monthly sales quota" {...field} />
              </FormControl>
              <FormDescription className="text-xs">
                Give your quota a name that will help you identify it.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="target_metric"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Target Metric</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Pick a target metric" />
                  </SelectTrigger>
                  <SelectContent>
                    {TARGET_METRICS.map((item, index) => (
                      <SelectItem key={index} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription className="text-xs">
                Pick the type of target you want to set.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="currency_code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Currency</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Pick a currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {currencies?.map((currency, index) => (
                      <SelectItem key={index} value={currency.code}>
                        {currency.code}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription className="text-xs">
                Pick the currency for the target value. Defaults to company
                currency ({defaultCurrency?.code}).
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="calculation_period"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Calculation Period</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Pick a calculation period" />
                  </SelectTrigger>
                  <SelectContent>
                    {CALCULATION_PERIODS.map((item, index) => (
                      <SelectItem key={index} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription className="text-xs">
                Pick the calculation period for the quota. Defaults to the
                frequency (Month/Quarter/Year) set in the compensation plan.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="deal_types"
          render={() => (
            <FormItem>
              <FormLabel>Deal types</FormLabel>
              {dealTypesRadioButtons.map((item) => (
                <FormField
                  key={item.value}
                  control={form.control}
                  name="deal_types"
                  render={({ field }) => {
                    return (
                      <FormItem
                        key={item.value}
                        className="flex flex-row items-start space-x-3 space-y-0"
                      >
                        <FormControl>
                          <Checkbox
                            checked={field.value?.includes(item.value)}
                            onCheckedChange={(checked) => {
                              return checked
                                ? field.onChange([...field.value, item.value])
                                : field.onChange(
                                    field.value?.filter(
                                      (value) => value !== item.value
                                    )
                                  );
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {item.label} Customers
                        </FormLabel>
                      </FormItem>
                    );
                  }}
                />
              ))}
              <FormMessage />
              <FormDescription className="text-xs">
                Choose which type of deals to include when calculating target
                attainment.
              </FormDescription>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="providers"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Deal Providers</FormLabel>
              <FormControl>
                <MultiSelect
                  options={getDealProvidersMultiSelectOptions(tokens)}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  placeholder="Select providers from list"
                  variant="inverted"
                  animation={0}
                  maxCount={3}
                  {...field}
                />
              </FormControl>
              <FormDescription className="text-xs">
                Where are the deals located? This list is based on providers
                connected to your account.
                <Button size={null} variant="link" className="pl-1 text-xs">
                  <Link href="/integrations">Add more providers here</Link>
                </Button>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Status</FormLabel>

              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="draft" />
                    </FormControl>
                    <FormLabel className="font-normal">Draft</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="published" />
                    </FormControl>
                    <FormLabel className="font-normal">Published</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormDescription className="text-xs">
                Only for visual purposes ATM. In the future, draft plans will
                only be visible to you.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="bg-[#6B6397]">
          Continue
        </Button>
      </form>
    </Form>
  );
};
