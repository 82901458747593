import {
  Database,
  NestedCompany,
  NestedGoal,
  NestedPlan,
} from "@revelate/types";
import { getMonth, getYear, isWithinInterval } from "date-fns";
import { getExchangeRateToCurrency } from "./currencies";

const getTargetValueForMonth = (
  company: NestedCompany,
  goals: NestedGoal[],
  date: Date
) => {
  const currentMonth = getMonth(date) + 1;

  const goal = goals.find((goal) => {
    if (
      goal.valid_from &&
      goal.valid_to &&
      !isWithinInterval(date, { start: goal.valid_from, end: goal.valid_to })
    ) {
      return false;
    }
    const { months } = goal;
    return months.includes(currentMonth);
  });

  const { target_value, quota } = goal || {};
  const { currency, target_metric } = quota || {};

  if (target_metric === "target_number_of_deals") return target_value || 0;

  // Use the exchange rate to convert the target value currency to the company currency
  const exchangeRate = currency
    ? getExchangeRateToCurrency(currency, company)
    : 1;

  return (target_value || 0) * exchangeRate;
};

const getYTDTargetValueForMonth = (
  company: NestedCompany,
  goals: NestedGoal[],
  date: Date
) => {
  const currentMonth = getMonth(date) + 1;
  const currentYear = getYear(date);

  let totalTargetValue = 0;
  for (let i = 1; i < currentMonth + 1; i++) {
    // First day of the month is enough for YTD calculation
    const targetValue = getTargetValueForMonth(
      company,
      goals,
      new Date(currentYear, i - 1)
    );

    // console.log("Target value for month", i, "is", targetValue);
    totalTargetValue += targetValue;
  }
  return totalTargetValue;
};

export const getTargetValueFromGoals = (
  calculationDate: Date,
  company: NestedCompany,
  plan: NestedPlan,
  goals: NestedGoal[],
  isCurrentPeriod: boolean
) => {
  const { time_period } = plan || {};
  if (!time_period) return 0;

  const targetValue = getTargetValueForMonth(company, goals, calculationDate);
  // const monthsInTimePeriod = getMonthsForTimePeriod(time_period);
  // TODO: Implement quarterly and yearly targets

  if (isCurrentPeriod) {
    return targetValue;
  } else {
    return getYTDTargetValueForMonth(company, goals, calculationDate);
  }
};

export const checkGoalCondition = (
  actualValue: number | null,
  targetValue: number | null,
  condition: Database["public"]["Tables"]["conditions"]["Row"]
) => {
  if (actualValue === null || targetValue === null) return false;
  const {
    operator,
    value: percentage,
    value_from: percentage_from,
    value_to: percentage_to,
    value_list,
  } = condition;
  if (operator === "is" && percentage) {
    // For quotas we interpret is as at least
    // TODO: hide this in the UI for quotas and only show gte
    return actualValue >= targetValue * percentage;
  }
  if (operator === "gt" && percentage) {
    return actualValue > targetValue * percentage;
  }
  if (operator === "gte" && percentage) {
    return actualValue >= targetValue * percentage;
  }
  if (operator === "lt" && percentage) {
    return actualValue < targetValue * percentage;
  }
  if (operator === "lte" && percentage) {
    return actualValue <= targetValue * percentage;
  }
  if (operator === "range" && percentage_from && percentage_to) {
    return (
      actualValue >= targetValue * percentage_from &&
      actualValue <= targetValue * percentage_to
    );
  }
  if (operator === "list" && value_list) {
    return false;
  }
  return false;
};

// FIXME: Get correct formula
export const getTotalTargetValueFromGoalsForCompany = (
  calculationDate: Date,
  company: NestedCompany,
  isCurrentPeriod: boolean
) => {
  const goals = company.quotas?.flatMap((quota) => quota.goals);

  return (
    company.plans
      ?.map((plan) =>
        getTargetValueFromGoals(
          calculationDate,
          company,
          plan,
          goals,
          isCurrentPeriod
        )
      )
      ?.reduce((acc, val) => acc + val, 0) || 0
  );
};
