import { Database, NestedTeam } from "@revelate/types";
import { supabaseClient } from "./client";

export const upsertTeam = async (
  team: Database["public"]["Tables"]["teams"]["Insert"]
): Promise<NestedTeam | null> => {
  const query = supabaseClient.from("teams").upsert({
    ...team,
    updated_at: new Date(),
  });

  const { data, error } = await query
    .select("*, users(*)")
    .returns<NestedTeam[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};

export const deleteTeam = async (teamId: number) => {
  const query = supabaseClient.from("teams").delete().eq("id", teamId);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const addUserToTeam = async (
  teamId: number,
  userId: string,
  isPrimary = false
) => {
  const query = supabaseClient
    .from("team_users")
    .insert({ user_id: userId, team_id: teamId, is_primary: isPrimary });
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};

export const deleteUserFromTeam = async (teamId: number, userId: string) => {
  const query = supabaseClient
    .from("team_users")
    .delete()
    .eq("user_id", userId)
    .eq("team_id", teamId);
  const { error } = await query.maybeSingle();
  if (error) {
    throw error;
  }
};
