import {
  Coins,
  Group,
  Handshake,
  Home,
  LineChart,
  Menu,
  Percent,
  PlugZap,
  Rocket,
  Target,
  User,
  Users,
} from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Link, useRoute } from "wouter";
import { Breadcrumbs } from "./components/breadcrumbs";
import { Routes } from "./components/routes";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { UserNav } from "./components/user-nav";
import { CalendarDateRangePicker } from "./components/date-range-picker";
import { Synchronize } from "./components/synchronize";
import TeamSwitcher from "./components/team-switcher";
import { useFeaturebase } from "@/hooks/useFeaturebase";
import { useAppStore } from "@/stores";
import { getScopeRoute } from "@/lib/routes";
import { getFullName, isDev } from "@revelate/utils";
import { ListItem } from "@revelate/types";
import { useScope } from "@/hooks/useScope";
import useSWR from "swr";
import { TableType, get } from "@/lib/supabase";
import { useEffect, useState } from "react";
import { getList } from "@/lib/app";
import { Loader } from "@/components/Loader";
import { useJune } from "@/hooks/useJune";

export const Layout = () => {
  const [list, setList] = useState<ListItem[]>([]);

  const { currentUser } = useAppStore((state) => state);
  const { data: company, isLoading } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => get(table, companyId)
  );
  const analytics = useJune();
  useScope({ list });

  const [plansMatched] = useRoute("/plans/*?");
  const [quotasMatched] = useRoute("/quotas/*?");
  const [currenciesMatched] = useRoute("/currencies/*?");
  const [dealsMatched] = useRoute("/deals/*?");
  const [reportsMatched] = useRoute("/reports/*?");
  const [usersMatched] = useRoute("/users/*?");
  const [integrationsMatched] = useRoute("/integrations/*?");
  const [teamsMatched] = useRoute("/teams/*?");

  const dashboardMatched =
    !plansMatched &&
    !reportsMatched &&
    !quotasMatched &&
    !usersMatched &&
    !currenciesMatched &&
    !integrationsMatched &&
    !dealsMatched &&
    !teamsMatched;
  const {
    open: openFeaturebasePopup,
    isLoading: isLoadingFeaturebasePopup,
    unviewedChangelogCount,
  } = useFeaturebase(isDev);

  const linkActive =
    "flex items-center gap-3 rounded-lg bg-muted px-3 py-2 text-black transition-all hover:text-black";
  const linkInactive =
    "flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground font-normal transition-all hover:text-[#000]";

  useEffect(() => {
    if (isLoading) return;
    const list = getList(company, currentUser);
    setList(list);
    // Analytics
    analytics?.identify(currentUser?.id, {
      email: currentUser?.email,
      name: getFullName(currentUser),
      avatar: currentUser?.avatar_url,
      // Add anything else about the user here
    });
    analytics?.group(currentUser?.company_id.toString(), {
      name: currentUser?.company?.name,
      avatar: currentUser?.company?.avatar_url,
      // Add anything else about the company here
    });
  }, [isLoading, company, currentUser, analytics]);

  if (isLoading) return <Loader />;

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[200px_1fr] lg:grid-cols-[220px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
            {/* <Link href="/" className="flex items-center gap-2 font-semibold">
              <Package2 className="h-6 w-6" />
              <span className="">Acme Inc</span>
            </Link>
            <Button variant="outline" size="icon" className="ml-auto h-8 w-8">
              <Bell className="h-4 w-4" />
              <span className="sr-only">Toggle notifications</span>
            </Button> */}
            <TeamSwitcher list={list} />
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
              <Link
                href={getScopeRoute("dashboard", null, [
                  "company",
                  "team",
                  "user",
                ])}
                className={dashboardMatched ? linkActive : linkInactive}
              >
                <Home className="h-4 w-4" />
                Dashboard
              </Link>
              {(currentUser?.role === "super_admin" ||
                currentUser?.role === "admin") && (
                <>
                  <Link
                    href={getScopeRoute("plans", null, ["plan"])}
                    className={plansMatched ? linkActive : linkInactive}
                  >
                    <Percent className="h-4 w-4" />
                    Compensation Plans
                  </Link>
                  <Link
                    href={getScopeRoute("quotas", null, ["quota"])}
                    className={quotasMatched ? linkActive : linkInactive}
                  >
                    <Target className="h-4 w-4" />
                    Sales Quotas
                  </Link>
                  <Link
                    href="/integrations"
                    className={integrationsMatched ? linkActive : linkInactive}
                  >
                    <PlugZap className="h-4 w-4" />
                    Integrations
                  </Link>
                  <Link
                    href={getScopeRoute("currencies", null, ["currency"])}
                    className={currenciesMatched ? linkActive : linkInactive}
                  >
                    <Coins className="h-4 w-4" />
                    Currencies
                  </Link>
                  <Link
                    href={getScopeRoute("deals", null, ["deal"])}
                    className={dealsMatched ? linkActive : linkInactive}
                  >
                    <Handshake className="h-4 w-4" />
                    Deals
                  </Link>
                  <Link
                    href={getScopeRoute("users", null, ["user"])}
                    className={usersMatched ? linkActive : linkInactive}
                  >
                    <User className="h-4 w-4" />
                    Users
                  </Link>
                  <Link
                    href={getScopeRoute("teams", null, ["team"])}
                    className={teamsMatched ? linkActive : linkInactive}
                  >
                    <Users className="h-4 w-4" />
                    Teams
                  </Link>
                </>
              )}
              <Link
                href={getScopeRoute("reports", null, [
                  "user",
                  "team",
                  "company",
                ])}
                className={reportsMatched ? linkActive : linkInactive}
              >
                <LineChart className="h-4 w-4" />
                Reports
              </Link>
              {/* <Link
                href={getScopeRoute("employees", scope, ["user"])}
                className={employeesMatched ? linkActive : linkInactive}
              >
                <Users className="h-4 w-4" />
                Employees
              </Link> */}
              {/* <Link
                href="#"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <Users className="h-4 w-4" />
                Employees
              </Link> */}
              {/* <Link
                href="#"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <LineChart className="h-4 w-4" />
                Analytics
              </Link> */}
              <div className="fixed bottom-2 h-10">
                <Link
                  href="#"
                  onClick={openFeaturebasePopup}
                  className={linkInactive}
                >
                  <>
                    <Rocket className="h-4 w-4" />
                    {isLoadingFeaturebasePopup ? (
                      <p>Loading...</p>
                    ) : (
                      <>
                        <p>Product Updates</p>
                        {unviewedChangelogCount > 0 && (
                          <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                            {unviewedChangelogCount}
                          </Badge>
                        )}
                      </>
                    )}
                  </>
                </Link>
              </div>
            </nav>
          </div>{" "}
          {/* <div className="mt-auto p-4">
            <Card x-chunk="dashboard-02-chunk-0">
              <CardHeader className="p-2 pt-0 md:p-4">
                <CardTitle>Upgrade to Pro</CardTitle>
                <CardDescription>
                  Unlock all features and get unlimited access to our support
                  team.
                </CardDescription>
              </CardHeader>
              <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
                <Button size="sm" className="w-full">
                  Upgrade
                </Button>
              </CardContent>
            </Card>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6">
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="shrink-0 md:hidden"
              >
                <Menu className="h-5 w-5" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="flex flex-col">
              <nav className="grid gap-2 text-lg font-medium">
                {/* <Link
                  href="#"
                  className="flex items-center gap-2 text-lg font-semibold"
                >
                  <Package2 className="h-6 w-6" />
                  <span className="sr-only">Acme Inc</span>
                </Link> */}
                <Link
                  href="/"
                  className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground"
                >
                  <Home className="h-5 w-5" />
                  Dashboard
                </Link>
                <Link
                  href="/plans"
                  className="mx-[-0.65rem] flex items-center gap-4 rounded-xl bg-muted px-3 py-2 text-foreground hover:text-foreground"
                >
                  <Coins className="h-5 w-5" />
                  Compensation Plans
                  {/* <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                    6
                  </Badge> */}
                </Link>
                <Link
                  href="/reports"
                  className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground"
                >
                  <LineChart className="h-5 w-5" />
                  Reports
                </Link>
                {/* <Link
                  href="#"
                  className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground"
                >
                  <Users className="h-5 w-5" />
                  Customers
                </Link>
                <Link
                  href="#"
                  className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground"
                >
                  <LineChart className="h-5 w-5" />
                  Analytics
                </Link> */}
              </nav>
              {/* <div className="mt-auto">
                <Card>
                  <CardHeader>
                    <CardTitle>Upgrade to Pro</CardTitle>
                    <CardDescription>
                      Unlock all features and get unlimited access to our
                      support team.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Button size="sm" className="w-full">
                      Upgrade
                    </Button>
                  </CardContent>
                </Card>
              </div> */}
            </SheetContent>
          </Sheet>
          <div className="w-full flex-1"></div>
          {/* <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Support</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu> */}
          {/* <UserNav /> */}
          <div className="ml-auto flex items-center space-x-4">
            <CalendarDateRangePicker />
            {(currentUser?.role === "super_admin" ||
              currentUser?.role === "admin") && <Synchronize />}
            <UserNav />
          </div>
        </header>
        <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
          <TooltipProvider>
            <Breadcrumbs />
            <Routes />
          </TooltipProvider>
        </main>
      </div>
    </div>
  );
};
