import * as React from "react";
import { CheckIcon, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { getFullName, sortByStringKey } from "@revelate/utils";
import { NestedTeam } from "@revelate/types";
import { useAppStore } from "@/stores";
import type { KeyedMutator } from "swr";
import { TableType, addUserToTeam, getAll } from "@/lib/supabase";
import { cn } from "@/lib/utils";
import useSWR from "swr";
import { Loader } from "@/components/Loader";

export function EmployeeCombobox({
  team,
  mutate,
}: {
  team: NestedTeam;
  mutate: KeyedMutator<NestedTeam | null>;
}) {
  const [open, setOpen] = React.useState(false);
  const currentUser = useAppStore((state) => state.currentUser);

  const {
    data: employees,
    error,
    isLoading,
  } = useSWR(
    [
      "users" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );

  const handleSelect = async (fullName: string) => {
    setOpen(false);
    const user = employees?.find((e) => getFullName(e) === fullName);
    if (!user) return;
    await addUserToTeam(team.id, user.id);
    const updatedUsers = [...(team.users || []), user];
    mutate({
      ...team,
      users: updatedUsers,
    } as NestedTeam);
  };

  if (isLoading) return <Loader />;
  if (error) return <div>Error loading employees</div>;

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button className="bg-[#6B6397]">
            <Plus className="mr-2 h-4 w-4" /> Add new employee
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <Command>
            <CommandInput placeholder="Search employee..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {employees
                  ?.filter((e) => !team?.users.map((u) => u.id).includes(e.id))
                  ?.sort(sortByStringKey("first_name"))
                  .map((user) => (
                    <CommandItem
                      key={user.id}
                      value={getFullName(user)}
                      onSelect={(id) => handleSelect(id)}
                    >
                      <span>{getFullName(user)}</span>
                      <CheckIcon
                        className={cn("ml-auto h-4 w-4", "opacity-0")}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
