import { Separator } from "@/components/ui/separator";
import { SidebarNav } from "./sidebar-nav";
import { Router, Route, Switch, useParams } from "wouter";
import { SettingsPage } from "./settings/page";
import AcceleratorsPage from "./accelerators/page";
import EmployeesPage from "./employees/page";
import useSWR from "swr";
import { Loader } from "lucide-react";
import { LoadingError } from "@/components/LoadingError";
import { TableType, get, getAll } from "@/lib/supabase";
import { useAppStore } from "@/stores";
import TeamsPage from "./teams/page";

export const EditPlanPage = () => {
  const { id } = useParams();
  const currentUser = useAppStore((state) => state.currentUser);

  const {
    data: plan,
    error,
    isLoading,
    mutate,
  } = useSWR(["plans" as TableType, id], ([table, id]) => get(table, id));

  const {
    data: tokens,
    error: tokensError,
    isLoading: tokensLoading,
  } = useSWR(
    [
      "tokens" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId)
  );

  const sidebarNavItems = [
    {
      title: "General",
      href: `/plans/plan/${id || "new"}`,
    },
    {
      title: "Accelerators",
      href: `${id ? `/plans/plan/${id}/accelerators` : "#"}`,
    },
    {
      title: "Teams",
      href: `${id ? `/plans/plan/${id}/teams` : "#"}`,
    },
    {
      title: "Employees",
      href: `${id ? `/plans/plan/${id}/employees` : "#"}`,
    },
  ];
  if (isLoading || tokensLoading) {
    return <Loader />;
  }

  if (error || tokensError) {
    return <LoadingError />;
  }

  return (
    <>
      <div className="hidden space-y-6 p-10 pb-16 md:block">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">
            {plan?.name || "New compensation plan"}
          </h2>
          <p className="text-muted-foreground">
            {plan
              ? "Manage your plan settings and configurations."
              : "Create a new plan to start paying commissions for employees."}
          </p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-4xl">
            {
              <Switch>
                <Route path="/plans/plan/new">
                  <SettingsPage tokens={tokens} mutate={mutate} />
                </Route>
                <Route path="/plans/plan/:id">
                  {plan && (
                    <SettingsPage plan={plan} tokens={tokens} mutate={mutate} />
                  )}
                </Route>
                <Route path={`/plans/plan/:id/teams`}>
                  {plan && <TeamsPage plan={plan} mutate={mutate} />}
                </Route>
                <Route path={`/plans/plan/:id/employees`}>
                  {plan && <EmployeesPage plan={plan} mutate={mutate} />}
                </Route>
                <Router base={`/plans/plan/${id}/accelerators`}>
                  {plan && (
                    <Route path="/*?">
                      <AcceleratorsPage plan={plan} mutate={mutate} />
                    </Route>
                  )}
                </Router>
              </Switch>
            }
          </div>
        </div>
      </div>
    </>
  );
};
