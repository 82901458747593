import { NestedTeam } from "@revelate/types";

export const getUserIdsForTeam = (teams: NestedTeam[], id: number) => {
  const team = teams.find((team) => team.id === id);
  if (!team) return [];
  return (
    team.users
      // .filter((user) => !primaryOnly || (primaryOnly && user.is_primary))
      .map((user) => user.id.toString())
  );
};
