import { NestedTeam } from "@revelate/types";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { getTeamInitials } from "@/lib/app";

export const TeamAvatarList = ({ teams }: { teams: NestedTeam[] }) => {
  return (
    <div className="flex items-center gap-1">
      {teams?.map((t, index) => (
        <Tooltip key={index}>
          <TooltipTrigger>
            <Avatar className="h-8 w-8">
              <AvatarFallback>{getTeamInitials(t)}</AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent>
            <div className="text-md font-semibold">{t?.name}</div>
            <p className="text-sm text-muted-foreground">
              {t?.users.length} team members
            </p>
          </TooltipContent>
        </Tooltip>
      ))}
    </div>
  );
};
