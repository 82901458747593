import {
  RevelateDealType,
  Database,
  DateRange,
  Scope,
  NestedDeal,
  NestedCompany,
  NestedCommission,
  TokenProvider,
  NestedTeam,
  DealRole,
} from "@revelate/types";
import {
  addMonths,
  areIntervalsOverlapping,
  differenceInCalendarWeeks,
  differenceInMonths,
  endOfDay,
  isBefore,
  isWithinInterval,
  startOfDay,
} from "date-fns";
import { getUserIdsForTeam } from "./teams";
import { getExchangeRateForDeal } from "./currencies";
import { isMultipleOf } from "@revelate/utils";

export type NestedDealStalled = NestedDeal & { comment?: string };

export const sortDealsByAmount = (
  deals: Database["public"]["Tables"]["deals"]["Row"][]
) => {
  return deals.sort((a, b) => a.value - b.value);
};

export const getDealValue = (
  company: NestedCompany | null,
  deal: NestedDeal,
  useOnboardingValue: boolean | null = false
) => {
  return (
    (useOnboardingValue ? deal.onboarding_value || 0 : deal.value || 0) *
    getExchangeRateForDeal(company, deal)
  );
};

export const getDealsValue = (
  company: NestedCompany | null,
  deals: NestedDeal[],
  useOnboardingValue: boolean | null = false
) => {
  return (
    deals?.reduce(
      (acc, deal) => acc + getDealValue(company, deal, useOnboardingValue),
      0
    ) || 0
  );
};

export const getAverageDealValue = (
  company: NestedCompany | null,
  deals: NestedDeal[]
) => {
  const totalValue = getDealsValue(company, deals);
  return totalValue / (deals.length || 1);
};

export const getDealsForScope = ({
  deals,
  scope,
  teams,
}: {
  deals: NestedDeal[];
  scope?: Scope | null;
  teams?: NestedTeam[];
}) => {
  // Get deals for scope
  if (!scope) return deals;
  const { type } = scope;
  if (type === "user") {
    return deals.filter((deal) => getDealOwner(deal)?.id === scope.value);
  }
  // TODO: Add team scope by filtering on users within a team
  if (teams && type === "team") {
    const userIds = getUserIdsForTeam(teams, Number(scope.value));
    return deals.filter(
      (deal) =>
        getDealOwner(deal) && userIds.includes(getDealOwner(deal)?.id || "")
    );
  }
  return deals;
};

const filterDealByDateRange = ({
  deals,
  dateRange,
  includeProjections = false,
}: {
  deals: NestedDeal[];
  dateRange: DateRange;
  includeProjections?: boolean;
}) => {
  const { from, to } = dateRange || {};
  if (!from || !to) return deals;
  const today = new Date();
  const filteredDeals = deals.filter((deal) => {
    const isInvoice = deal.provider === "fortnox";

    const isClosedWithinRange =
      from &&
      to &&
      deal.closed_at &&
      isWithinInterval(new Date(deal.closed_at), {
        start: startOfDay(from),
        end: endOfDay(to),
      }) &&
      isBefore(new Date(deal.closed_at), endOfDay(new Date(to))); // Make sure we don't include midnight deals

    const isClosedWithinContractLength =
      from &&
      to &&
      deal.closed_at &&
      deal.contract_length_months &&
      deal.customer_deal_count === 1 && // Only count the first invoice
      // Contract is still live
      areIntervalsOverlapping(
        {
          start: startOfDay(deal.closed_at),
          end: endOfDay(addMonths(deal.closed_at, deal.contract_length_months)),
        },
        {
          start: startOfDay(from),
          end: endOfDay(to),
        }
      );

    const isInvoiceCollectionMonth =
      deal.closed_at &&
      deal.invoice_length_months &&
      differenceInMonths(today, deal.closed_at) > 0 &&
      isMultipleOf(
        differenceInMonths(today, deal.closed_at),
        deal.invoice_length_months
      );

    return includeProjections && isInvoice
      ? isClosedWithinContractLength && isInvoiceCollectionMonth
      : isClosedWithinRange;
  });

  return filteredDeals;
};

const filterDealsByType = ({
  deals,
  dealTypes,
}: {
  deals: NestedDeal[];
  dealTypes: RevelateDealType[];
}) => {
  return deals.filter((deal) =>
    dealTypes.includes(deal.deal_type as RevelateDealType)
  );
};

export const getDealsForDateRange = ({
  deals: inputDeals,
  dateRange,
  scope,
  teams,
  dealTypes,
  providers,
  dealRole,
  userId,
  includeProjections = false,
}: {
  deals: NestedDeal[];
  dateRange: DateRange;
  scope?: Scope | null;
  teams?: NestedTeam[];
  dealTypes?: RevelateDealType[];
  providers?: TokenProvider[];
  dealRole?: DealRole;
  userId?: string;
  includeProjections?: boolean;
}) => {
  // Filter by scope
  let deals = getDealsForScope({ deals: inputDeals, scope, teams });
  if (!deals) return [];
  // Filter by date range
  deals = filterDealByDateRange({ deals, dateRange, includeProjections });
  if (dealTypes) {
    // Filter by deal type
    deals = deals.filter((deal) =>
      dealTypes.includes(deal.deal_type as RevelateDealType)
    );
  }
  if (providers) {
    // Filter by provider
    deals = deals.filter((deal) => providers.includes(deal.provider));
  }

  if (dealRole && userId) {
    // Filter by deal role
    deals = deals.filter((deal) => {
      // TODO: Filter by user id
      const dealUser = deal?.users?.find(
        (dealUser) => dealUser?.user_id === userId
      );
      if (dealRole === "management") return true; // All deals
      return dealUser && dealUser?.role === dealRole;
    });
  }

  if (includeProjections) {
    deals = deals.filter((d) => !d.is_lost);
  } else {
    deals = deals.filter((d) => d.is_won);
  }
  return deals?.sort((a, b) => b.value - a.value);
};

export const getDealValueFromCommissions = (
  commissions: NestedCommission[]
) => {
  if (!commissions || commissions.length === 0) return 0;
  return commissions?.reduce(
    (acc, commission) => commission.deal?.value ?? acc ?? 0, // don't accumulate, just take a found value
    0
  );
};

export const getStalledDeals = (
  deals: NestedDeal[],
  limit: number = 3
): NestedDealStalled[] => {
  return deals
    .filter((d) => !d.is_closed)
    .map(mapStalledDealWithReason)
    .filter((d) => d !== null)
    .toSorted((a, b) => b.value - a.value)
    .slice(0, limit);
};

const mapStalledDealWithReason = (
  deal: NestedDeal
): NestedDealStalled | null => {
  const today = new Date();

  const sameStageDurationInWeeks = deal.next_step_updated_at
    ? differenceInCalendarWeeks(today, deal.next_step_updated_at)
    : undefined;

  const isNextStepAssigned = deal.next_step && deal.next_step.length > 0;

  const lastUpdatedAgoInWeeks = deal.last_activity_at
    ? differenceInCalendarWeeks(today, deal.last_activity_at)
    : undefined;

  if (sameStageDurationInWeeks && sameStageDurationInWeeks > 3) {
    return {
      ...deal,
      comment: `Same stage for ${sameStageDurationInWeeks} weeks`,
    };
  }

  if (!isNextStepAssigned) {
    return { ...deal, comment: "No next step assigned" };
  }

  if (lastUpdatedAgoInWeeks && lastUpdatedAgoInWeeks > 2) {
    return { ...deal, comment: `No updates in ${lastUpdatedAgoInWeeks} weeks` };
  }

  return null;
};

export const getDealOwner = (deal: NestedDeal) => {
  const owner = deal?.users?.find((user) => user.role === "owner");
  return owner?.data || null;
};
