import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { getCurrencyFormatted, getUsersList } from "@/lib/app";
import { getRoute } from "@/lib/routes";
import { useAppStore } from "@/stores";
import { calculateCommissionForUser } from "@revelate/calc";
import { NestedCompany, NestedUser } from "@revelate/types";
import { ChevronRight } from "lucide-react";
import { useLocation } from "wouter";

export function RecentSales({
  company,
  scopedUsers,
}: {
  company: NestedCompany;
  scopedUsers: NestedUser[];
}) {
  const { dateRange } = useAppStore((state) => state);

  const scopedUsersWithCommissions =
    company && scopedUsers
      ? scopedUsers?.map((user) => {
          const commissions = calculateCommissionForUser({
            dateRange,
            company,
            user,
            deals: user.deals,
          });
          return {
            ...user,
            commissions,
          };
        })
      : [];

  const [, setLocation] = useLocation();

  return (
    <div className="space-y-8">
      {getUsersList(scopedUsersWithCommissions)?.map((user, index) => (
        <div className="flex items-center" key={index}>
          <Avatar className="h-9 w-9">
            <AvatarImage src={user.avatar} alt="Avatar" />
            <AvatarFallback>{user.avatar_fallback}</AvatarFallback>
          </Avatar>
          <div className="ml-4 space-y-1">
            <p className="text-sm font-medium leading-none">{user.name}</p>
            <p className="text-sm text-muted-foreground">{user.email}</p>
          </div>
          <div className="ml-auto text-right font-medium">
            {getCurrencyFormatted(user.value)}
          </div>
          <div className="ml-3 font-medium">
            <Button
              onClick={() =>
                setLocation(getRoute("dashboard", "user", user.id))
              }
              variant="outline"
              size="icon"
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
